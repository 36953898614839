import "bootstrap/dist/js/bootstrap.min.js";
import "font-awesome/css/font-awesome.min.css";
import "jquery/dist/jquery.min.js";
// import "font-awesome/css/font-awesome.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./assets/css/bootstrap.min.css";
import "./assets/css/custom.css";
import "./assets/css/mid.css";
import "./assets/css/new-style.css";
import "./assets/css/responsive.css";
import "./assets/css/slick.css";
import "./assets/css/styles.css";

import GetQRProfile from "./ListingPage/LinkedinProfile";
import QRProfile from "./ListingPage/Profile";
import QRCode from "./ListingPage/QRCode"
 

function App() {
  return (
    <div>
      <Router>
        <Switch>
         
          <Route path="/linkedinprofile/:slug/:index" component={GetQRProfile} />
          <Route path="/lp/:index" component={GetQRProfile} />
          <Route path="/profile/:slug/:index" component={QRProfile}/>
          <Route path="/qrcode" component={QRCode}/>
        
        </Switch>
      </Router>
    </div>
  );
}

export default App;
